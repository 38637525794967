<template>
    <div>
        <b-row class="match-height">
            <b-col xl="7" md="7" sm="7">
                <b-row>
                    <b-col xl="12" md="12" sm="12">
                        <b-card class="card-app-design p-1" no-body>
                            <div class="border-bottom pb-1 mb-1 d-flex">
                                <feather-icon icon="ListIcon" size="19"/>
                                <h4 class="mb-0 ml-50">Son İşlem Kayıtları</h4>
                            </div>
                            <b-card-body class="p-0">
                                <b-table
                                        :items="dataInfo.latestList"
                                        :fields="latestFields"
                                        class="mb-0"
                                        striped responsive bordered hover>
                                    <template #cell(date)="data">
                                        <date-time-column-table :data="data.value"></date-time-column-table>
                                    </template>

                                    <template #cell(title)="data">
                                        <div class="text-nowrap">
                                            {{ data.value }}
                                        </div>
                                    </template>

                                    <template #cell(ownerName)="data">
                                        <div class="text-nowrap">
                                            {{ data.value }}
                                        </div>
                                    </template>

                                    <template #cell(type)="data">
                                        <b-media no-body v-if="data.value === 'Activity'">
                                            <b-media-aside class="mr-1">
                                                <b-avatar size="30" variant="light-primary">
                                                    <feather-icon size="16" icon="CodesandboxIcon"/>
                                                </b-avatar>
                                            </b-media-aside>
                                            <b-media-body class="my-auto">
                                                <b-card-text>
                                                    Aktivite
                                                </b-card-text>
                                            </b-media-body>
                                        </b-media>
                                        <b-media no-body v-if="data.value === 'FormRequest'">
                                            <b-media-aside class="mr-1">
                                                <b-avatar size="30" variant="light-warning">
                                                    <feather-icon size="16" icon="InboxIcon"/>
                                                </b-avatar>
                                            </b-media-aside>
                                            <b-media-body class="my-auto">
                                                <b-card-text>
                                                    Talep
                                                </b-card-text>
                                            </b-media-body>
                                        </b-media>
                                        <b-media no-body v-if="data.value === 'FormExpense'">
                                            <b-media-aside class="mr-1">
                                                <b-avatar size="30" variant="light-info">
                                                    <feather-icon size="16" icon="BriefcaseIcon"/>
                                                </b-avatar>
                                            </b-media-aside>
                                            <b-media-body class="my-auto">
                                                <b-card-text>
                                                    Masraf
                                                </b-card-text>
                                            </b-media-body>
                                        </b-media>
                                        <b-media no-body v-if="data.value === 'CustomerComplaint'">
                                            <b-media-aside class="mr-1">
                                                <b-avatar size="30" variant="light-danger">
                                                    <feather-icon size="16" icon="ApertureIcon"/>
                                                </b-avatar>
                                            </b-media-aside>
                                            <b-media-body class="my-auto">
                                                <b-card-text>
                                                    Şikayet
                                                </b-card-text>
                                            </b-media-body>
                                        </b-media>
                                    </template>
                                </b-table>
                            </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>
            </b-col>
            <b-col xl="5" md="5" sm="5">
                <b-card class="card-app-design p-1 statistics-card" no-body>
                    <div class="border-bottom pb-1 d-flex">
                        <feather-icon icon="TrelloIcon" size="19"/>
                        <h4 class="mb-0 ml-50">İstatistikler</h4>
                    </div>
                    <b-card-body class="statistics-body pb-0">
                        <b-row>
                            <b-col xl="4" sm="4" class="mb-2">
                                <b-media no-body>
                                    <b-media-aside class="mr-2">
                                        <b-avatar size="48" variant="primary">
                                            <feather-icon size="24" icon="BookIcon"/>
                                        </b-avatar>
                                    </b-media-aside>
                                    <b-media-body class="my-auto">
                                        <h5 class="font-weight-bolder mb-0">
                                            {{ new Intl.NumberFormat().format(!busy ? dataInfo.customerCount : 0) }}
                                        </h5>
                                        <b-card-text class="font-small-3 mb-0">
                                            Müşteri
                                        </b-card-text>
                                    </b-media-body>
                                </b-media>
                            </b-col>
                            <b-col xl="4" sm="4" class="mb-2">
                                <b-media no-body>
                                    <b-media-aside class="mr-2">
                                        <b-avatar size="48" variant="secondary">
                                            <feather-icon size="24" icon="CodesandboxIcon"/>
                                        </b-avatar>
                                    </b-media-aside>
                                    <b-media-body class="my-auto">
                                        <h5 class="font-weight-bolder mb-0">
                                            {{ new Intl.NumberFormat().format(!busy ? dataInfo.activityCount : 0) }}
                                        </h5>
                                        <b-card-text class="font-small-3 mb-0">
                                            Aktivite
                                        </b-card-text>
                                    </b-media-body>
                                </b-media>
                            </b-col>
                            <b-col xl="4" sm="4" class="mb-2">
                                <b-media no-body>
                                    <b-media-aside class="mr-2">
                                        <b-avatar size="48" variant="warning">
                                            <feather-icon size="24" icon="InboxIcon"/>
                                        </b-avatar>
                                    </b-media-aside>
                                    <b-media-body class="my-auto">
                                        <h5 class="font-weight-bolder mb-0">
                                            {{ new Intl.NumberFormat().format(!busy ? dataInfo.formCount : 0) }}
                                        </h5>
                                        <b-card-text class="font-small-3 mb-0">
                                            Talep
                                        </b-card-text>
                                    </b-media-body>
                                </b-media>
                            </b-col>
                        </b-row>
                        <b-row class="border-top pt-2">
                            <b-col xl="4" sm="4" class="mb-2">
                                <b-media no-body>
                                    <b-media-aside class="mr-2">
                                        <b-avatar size="48" variant="info">
                                            <feather-icon size="24" icon="UsersIcon"/>
                                        </b-avatar>
                                    </b-media-aside>
                                    <b-media-body class="my-auto">
                                        <h5 class="font-weight-bolder mb-0">
                                            {{ new Intl.NumberFormat().format(!busy ? dataInfo.contactCount : 0) }}
                                        </h5>
                                        <b-card-text class="font-small-3 mb-0">
                                            Kişi
                                        </b-card-text>
                                    </b-media-body>
                                </b-media>
                            </b-col>
                            <b-col xl="4" sm="4" class="mb-2">
                                <b-media no-body>
                                    <b-media-aside class="mr-2">
                                        <b-avatar size="48" variant="warning">
                                            <feather-icon size="24" icon="HexagonIcon"/>
                                        </b-avatar>
                                    </b-media-aside>
                                    <b-media-body class="my-auto">
                                        <h5 class="font-weight-bolder mb-0">
                                            {{ new Intl.NumberFormat().format(!busy ? dataInfo.actionCount : 0) }}
                                        </h5>
                                        <b-card-text class="font-small-3 mb-0">
                                            Aksiyon
                                        </b-card-text>
                                    </b-media-body>
                                </b-media>
                            </b-col>
                            <b-col xl="4" sm="4" class="mb-2">
                                <b-media no-body>
                                    <b-media-aside class="mr-2">
                                        <b-avatar size="48" variant="danger">
                                            <feather-icon size="24" icon="ApertureIcon"/>
                                        </b-avatar>
                                    </b-media-aside>
                                    <b-media-body class="my-auto">
                                        <h5 class="font-weight-bolder mb-0">
                                            {{ new Intl.NumberFormat().format(!busy ? dataInfo.complaintCount : 0) }}
                                        </h5>
                                        <b-card-text class="font-small-3 mb-0">
                                            Şikayet
                                        </b-card-text>
                                    </b-media-body>
                                </b-media>
                            </b-col>
                        </b-row>
                        <b-row class="border-top pt-2">
                            <b-col xl="4" sm="4" class="mb-2">
                                <b-media no-body>
                                    <b-media-aside class="mr-2">
                                        <b-avatar size="48" variant="warning">
                                            <feather-icon size="24" icon="BriefcaseIcon"/>
                                        </b-avatar>
                                    </b-media-aside>
                                    <b-media-body class="my-auto">
                                        <h5 class="font-weight-bolder mb-0">
                                            {{ new Intl.NumberFormat().format(!busy ? dataInfo.expenseCount : 0) }}
                                        </h5>
                                        <b-card-text class="font-small-3 mb-0">
                                            Masraf
                                        </b-card-text>
                                    </b-media-body>
                                </b-media>
                            </b-col>
                            <b-col xl="4" sm="4" class="mb-2">
                                <b-media no-body>
                                    <b-media-aside class="mr-2">
                                        <b-avatar size="48" variant="primary">
                                            <feather-icon size="24" icon="BarChartIcon"/>
                                        </b-avatar>
                                    </b-media-aside>
                                    <b-media-body class="my-auto">
                                        <h5 class="font-weight-bolder mb-0">
                                            {{ new Intl.NumberFormat().format(!busy ? dataInfo.reportCount : 0) }}
                                        </h5>
                                        <b-card-text class="font-small-3 mb-0">
                                            Rapor
                                        </b-card-text>
                                    </b-media-body>
                                </b-media>
                            </b-col>
                            <b-col xl="4" sm="4" class="mb-2">
                                <b-media no-body>
                                    <b-media-aside class="mr-2">
                                        <b-avatar size="48" variant="info">
                                            <feather-icon size="24" icon="FilmIcon"/>
                                        </b-avatar>
                                    </b-media-aside>
                                    <b-media-body class="my-auto">
                                        <h5 class="font-weight-bolder mb-0">
                                            {{ new Intl.NumberFormat().format(!busy ? dataInfo.supportVideoCount : 0) }}
                                        </h5>
                                        <b-card-text class="font-small-3 mb-0">
                                            Eğitim
                                        </b-card-text>
                                    </b-media-body>
                                </b-media>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>
                <b-card class="card-app-design p-1" no-body>
                    <div class="border-bottom pb-1 mb-1 d-flex">
                        <feather-icon icon="SmartphoneIcon" size="19"/>
                        <h4 class="mb-0 ml-50">Onay Bekleyen Cihazlar</h4>
                    </div>

                    <div class="border-bottom pb-1 mb-1" v-for="(device,key,index) in devices" :key="device.id" v-if="devices.length > 0">
                        <b-media no-body>
                            <b-media-aside class="mr-75">
                                <b-avatar
                                        :src="device.userPicture"
                                        :variant="`light-primary`"
                                        :text="avatarText(device.userName)"
                                        size="30"/>
                            </b-media-aside>
                            <b-media-body>
                                <h6 class="font-weight-bolder mb-25">
                                    {{ device.userName }}
                                </h6>
                                <b-card-text>{{ device.deviceModel }} - {{ device.deviceName }}</b-card-text>
                                <b-link @click="approveDevice(device.id, device.userId, true)">
                                    <div class="d-inline-flex align-items-center">
                                        <feather-icon icon="CheckIcon" size="18" class="mr-50"/>
                                        <span>Onayla</span>
                                    </div>
                                </b-link>
                            </b-media-body>
                        </b-media>
                    </div>

                    <div class="empty-card h-100" v-if="devices.length === 0">
                        <b-media no-body>
                            <b-media-aside class="mb-1">
                                <b-avatar size="100" variant="light-warning">
                                    <feather-icon size="40" icon="SmartphoneIcon"/>
                                </b-avatar>
                            </b-media-aside>
                            <b-media-body class="my-auto">
                            </b-media-body>
                        </b-media>
                        <b-card-text class="font-small-3 mb-0">
                            Onay bekleyen cihaz bulunmamaktadır...
                        </b-card-text>
                    </div>

                </b-card>
            </b-col>
        </b-row>

        <Overlay :busy="busy"></Overlay>

    </div>
</template>

<script>
import {BAvatar, BBadge, BCard, BCardBody, BCardHeader, BCardText, BCardTitle, BCol, BFormCheckbox, BLink, BMedia, BMediaAside, BMediaBody, BRow, BTable} from 'bootstrap-vue'
import store from "@/store"
import storeModule from "@/views/common/home/store"
import {onUnmounted, ref} from "@vue/composition-api";
import {useToast} from "vue-toastification/composition";
import {toastMessage} from "@core/utils/utils";
import {formatDateTime, formatDate, formatTime, avatarText} from "@core/utils/filter"
import Overlay from "@/components/Overlay.vue";
import Vue from "vue";
import {DateColumnTable, DateTimeColumnTable} from "@/components/Table";

export default {
    components: {
        DateTimeColumnTable,
        DateColumnTable,
        BTable, BFormCheckbox,
        BCardBody,
        BCardHeader,
        Overlay,
        BCardTitle,
        BMediaBody,
        BMediaAside,
        BMedia,
        BBadge,
        BAvatar,
        BCol,
        BRow,
        BCard,
        BCardText,
        BLink,
    },
    setup() {
        const STORE_MODULE_NAME = 'store'
        if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
        onUnmounted(() => {
            if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
        })

        const toast = useToast()
        const busy = ref(false)

        const dataInfo = ref({})
        const devices = ref([])

        busy.value = true
        store.dispatch('store/fetchItem').then(response => {
            dataInfo.value = response.data.data
        }).catch(error => {
            toastMessage(toast, 'danger', error.response.data.message)
        }).finally(message => {
            busy.value = false
        })

        busy.value = true
        store.dispatch('store/inactiveDevices').then(response => {
            devices.value = response.data.data
        }).catch(error => {
            toastMessage(toast, 'danger', error.response.data.message)
        }).finally(message => {
            busy.value = false
        })

        const approveDevice = (id, userId, isActive) => {
            Vue.swal({
                title: 'Onaylıyor musunuz?',
                text: 'Cihaz onaylanacaktır!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Evet, Onayla',
                cancelButtonText: 'Vazgeç',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-secondary ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    store.dispatch('store/updateDevice', {id: id, userId: userId, isActive: isActive}).then(response => {
                        if (response.data.success) {
                            Vue.swal({
                                icon: 'success',
                                title: 'Başarılı!',
                                text: 'Cihaz onaylandı.',
                                confirmButtonText: 'Tamam',
                                customClass: {
                                    confirmButton: 'btn btn-primary',
                                },
                            })
                            devices.value = devices.value.filter(function (e) {
                                return e.id !== id
                            })
                        }
                    }).catch(error => {
                        Vue.swal({
                            icon: 'error',
                            title: 'Hata!',
                            text: error.response.data.message,
                            confirmButtonText: 'Tamam',
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                        })
                    })
                }
            })
        }

        const latestFields = [
            {key: 'type', label: 'İşlem', thStyle: {width: "1%"}},
            {key: 'date', label: 'İşlem Zamanı'},
            {key: 'ownerName', label: 'Kullanıcı'},
            {key: 'title', label: 'Müşteri'},
        ];

        return {
            dataInfo,
            busy,
            devices,
            latestFields,

            formatDateTime,
            formatDate,
            formatTime,
            avatarText,
            approveDevice,
        }
    },
}
</script>

<style>
.statistics-card {
    flex: initial !important;
}

.empty-card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
</style>
