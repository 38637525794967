import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchItem(ctx) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/dashboard/v2`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        inactiveDevices(ctx) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/userdevices/inactives`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        updateDevice(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .put('/userdevices', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
